import rootScope from "../../lib/rootScope";
let manager = rootScope.managers;
import ListenerSetter from "../../helpers/listenerSetter";
import getPreviewURLFromBytes from "../../helpers/bytes/getPreviewURLFromBytes";
import apiManagerProxy from "../../lib/mtproto/mtprotoworker";

import { AppChatsManager } from "../../lib/appManagers/appChatsManager";
import appDialogsManager from "../../lib/appManagers/appDialogsManager";
import { AppManager } from "../../lib/appManagers/manager";
import getProxiedManagers from "../../lib/appManagers/getProxiedManagers";
import { sendMsg, sendText, sendImage, sendImageByBase64 } from "./messageUtil";
import { Api_OnlineHeart, getConfig } from "../api";

import IDBStorage, { IDB } from "../../lib/files/idb";

import { TolinkBroadcaster } from "./TolinkBroadcaster";
import { isTolinkLogin } from "./utils";
import { modifyUserInfo } from "../api/authApi";
import { getMyLang, getTranslateSendSwitch, translateSend } from "./translate_util";
import TolinkObservers from "./TolinkObservers";
import SecretStore from "./SecretStore";
import { CacheTypes, putCache } from "./cacheUtil";
import { setPromiseMiddleware } from "./PromiseMiddleware";
import { setAiMsg2Pool } from "../../tolink/auto_reply";

import { Send_image_ai } from "../../tolink/auto_reply";
import { Api_TgBot_Report } from "../api/index";

// rootScope.managers.apiUpdatesManager.addMultipleEventsListeners({
//   message: (msg) => {
//     console.log(`**addMultipleEventsListeners message`, msg);
//   },
// });

// console.log("**rootScope", rootScope);

let ls = new ListenerSetter();
ls.add(rootScope)("avatar_update", (e) => {
  console.log(`****avatar_update`, e);
  getUserInfo();
  modifyUserInfo();
});
ls.add(rootScope)("peer_title_edit", (e) => {
  console.log(`****peer_title_edit`, e);
  getUserInfo();
});
ls.add(rootScope)("user_auth", () => {
  console.log(`****LISTENER user_auth`, `user_auth`);
  getUserInfo();
  modifyUserInfo();
});
ls.add(rootScope)("message_sent", (msg) => {
  console.log(`****message_sent`, msg);
  if (getTranslateSendSwitch()) translateSent(msg);
});

ls.add(rootScope)("message_receive", async (e) => {
  console.log(`****message_receive  监听到新消息`, e);
  let options = {
    message: e,
  };
  let user = await rootScope.managers.appUsersManager.getSelf();
  console.log("****getSelf :>> ", user);

  //如果收到用户消息，进入AI回复流程
  if (e.peer_id._ == "peerUser") {
    // 监听消息 不是自己的消息才执行
    if (user.id != e.fromId) {
      // console.log("****启动AI回复 :>> ");
      setAiMsg2Pool(options);
    }
  }
});

async function translateSent(msgObj) {
  const msg = msgObj.message;

  const entities = msg.totalEntities;
  const translation = await translateSend(msg.message, getMyLang());

  if (translation) {
    entities.push({
      _: "messageEntityTextUrl",
      offset: 0,
      length: 0,
      url: `translate-text://translate/${
        translation ? translation : msg.message
      }`,
    });
    putCache(
      `translate-${msg.peerId}-${msg.id}`,
      CacheTypes.TRANSLATE,
      translation
    );
    console.log(
      `****Translated text and push to entities, with text: ${msg.message}, lang: en, result: ${translation}, entities: ${entities}`
    );
  }
  rootScope.managers.appMessagesManager
    .editMessage(msg, msg.message, {
      entities: entities,
    })
    .then(() => {
      rootScope.dispatchEvent("message_edit", msgObj);
    });
}

async function blobUrlToBase64(blobUrl) {
  console.log("**blobUrlToBase64 :>> ", blobUrl);
  // Fetch the blob from the URL
  const response = await fetch(blobUrl);
  const blob = await response.blob();

  console.log("**blob");

  // Create a promise to convert the blob to a base64 string
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result); // This is the base64 string
    reader.onerror = reject;
    reader.readAsDataURL(blob); // Read the blob as a data URL
  });
}

export async function setUserInfo2Pc(user) {
  console.log("**setUserInfo2Pc user11111 :>> ", JSON.stringify(user));

  let json = {};

  if (user) {
    let big_photo;
    try {
      big_photo = await apiManagerProxy.loadAvatar(
        user.id,
        user.photo,
        // "photo_big"
        "photo_small"
      );
    } catch (e) {
      console.log("**big_photo :>> ", e);
    }
    let base64 = await blobUrlToBase64(big_photo);
    json = {
      tgUid: user.id,
      tgName: user.first_name + " " + (user.last_name ?? ""),
      tgAvatar: base64,
      tgAccount: user.username ?? "",
      tgPhone: user.phone ?? "",
      token: sessionStorage.getItem("tolink_token"),
      groupId: sessionStorage.getItem("tolink_groupId"),
      groupCode: sessionStorage.getItem("tolink_groupCode"),
      groupMemberId: sessionStorage.getItem("tolink_groupMemberId"),
      groupName: sessionStorage.getItem("tolink_groupName"),
      groupMemberMark: sessionStorage.getItem("tolink_groupMemberMark"),
    };
    // console.log("**setUserInfo2Pc4444 :>> ", JSON.stringify(json));
  }

  if (isTolinkLogin()) {
    // const tc = TolinkObservers.get().getDialogsAllUnRead()
    const tc = await getTotalUnreadCount();
    json.totalUnread = tc;
  }

  fetch(`yaychat://set_info`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(json),
  });
}

export function getUserInfo() {
  rootScope.managers.appUsersManager
    // .getUser()
    .getSelf()
    .then((user) => {
      if (user) {
        setUserInfo2Pc(user);
      }
    });
}

export function cleanUserInfo() {
  setUserInfo2Pc();
}

export async function getUserByPeerId(peerId) {
  return await rootScope.managers.appUsersManager.getUser(peerId);
}

export async function setTyping(peerId) {
  rootScope.managers.appMessagesManager.setTyping(
    peerId,
    { _: "sendMessageTypingAction" },
    undefined,
    undefined
  );
}

export function toggleAIReply(status) {
  sessionStorage.setItem("aiReplyStatus", status);
  console.log("**aiReplyStatus :>> ", status);
}
export async function getAIReplyStatus(status) {
  try {
    let rsp = await getConfig();
    if (rsp && rsp.code == 200) {
      return rsp.data.aiReply;
    }
  } catch (e) {
    console.log(e);
  }
  return sessionStorage.getItem("aiReplyStatus");
}

export function getAIConfig() {
  // fetch(`yaychat://get_config`, {
  //   method: "GET",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // });

  sendToPc("yaychat://get_config", null, "GET");
}

export function setAIConfig(config) {
  if (config) {
    console.log("**config :>> ", config.aiReply);
    sessionStorage.setItem("aiReplyStatus", config.aiReply);
    if (config.aws) {
      SecretStore.getIns().set(SecretStore.KEY_AWS, config.aws);
    }
    if (config.headers) {
      sessionStorage.setItem("headers", JSON.stringify(config.headers));
    }
  }
}

export function setHasRead(msg) {
  console.log("设置消息已读 :>> ");
  let peerId = msg.peerId;
  let msgIds = [];
  msgIds.push(msg.mid);
  console.log("**peerId :>> ", peerId);
  console.log("**msgIds :>> ", msgIds);
  // rootScope.managers.appMessagesManager.readMessages(peerId, msgIds);

  rootScope.managers.appMessagesManager.readHistory(peerId, msg.mid);
}

export async function setActiveUser(peerId) {
  let res = await getUserByPeerId(peerId);
  console.log("** RES yaychat://set-active-user :>> ", res);
  sendToPc("yaychat://set-active-user", res);
}

export async function getConvs({ type, status, keyword }) {
  console.log("**getConvs :>> ");
  // let indexDb = await rootScope.managers.appStoragesManager.loadStorages();
  let idb = new IDBStorage({ name: "tweb" });
  // let users = indexDb.users;
  // let chats = indexDb.chats;
  let users = await idb.getAll("users");
  let chats = await idb.getAll("chats");
  let dialogs = await idb.getAll("dialogs");
  let indexDb = { idb: idb, users: users, chats: chats, dialogs: dialogs };
  let arr = filterDialogs(indexDb, type, status, keyword);

  let userInfoArr = [];

  for (var item of arr) {
    let type = item.peer._;
    if (type == "peerChat") {
      let chat = chats.find((c) => {
        return c.id == item.peer.chat_id;
      });
      if (chat) {
        let base64 = await getAvatarBase64(item.peerId, chat.photo);
        let obj = {
          id: chat.id,
          avatar: base64,
          name: chat.title,
          userName: chat.username,
          time: item.topMessage?.date ?? Date.now(),
        };
        userInfoArr.push(obj);
      }
    } else if (type == "peerUser") {
      let user = users.find((u) => {
        return u.id == item.peerId;
      });
      if (user) {
        let base64 = await getAvatarBase64(item.peerId, user.photo);
        let obj = {
          id: user.id,
          avatar: base64,
          name: user.first_name + " " + (user.last_name ?? ""),
          userName: user.username,
          time: item.topMessage?.date ?? Date.now(),
        };
        userInfoArr.push(obj);
      }
    } else if (type == "peerChannel") {
      let chat = chats.find((c) => {
        return c.id == item.peer.channel_id;
      });
      if (chat) {
        let base64 = await getAvatarBase64(item.peerId, chat.photo);
        let obj = {
          id: chat.id,
          avatar: base64,
          name: chat.title,
          userName: chat.username,
          time: item.topMessage?.date ?? Date.now(),
        };
        userInfoArr.push(obj);
      }
    }
  }

  console.log("**userInfoArr :>> ", userInfoArr);
  return userInfoArr;
}

async function getAvatarBase64(peerId, photo) {
  let base64;
  if (photo) {
    let big_photo;
    try {
      big_photo = await apiManagerProxy.loadAvatar(
        peerId,
        photo,
        "photo_small"
      );
    } catch (e) {
      console.log("**big_photo :>> ", e);
    }

    if (big_photo) {
      base64 = await blobUrlToBase64(big_photo);
    }
  }
  return base64;
}

function filterDialogs(indexDb, type, status, keyword) {
  let allDialogs = indexDb.dialogs;
  console.log("allDialogs---", JSON.stringify(allDialogs));
  let arr = [];
  if (type == 0) {
    arr = allDialogs;
  }
  if (type == 1) {
    arr = allDialogs.filter((item) => {
      return item.peer._ == "peerUser";
    });
  }
  if (type == 2) {
    arr = allDialogs.filter((item) => {
      return item.peer._ == "peerChat";
    });
  }
  if (type == 3) {
    arr = allDialogs.filter((item) => {
      return item.peer._ == "peerChannel";
    });
  }

  if (status == 0) {
    arr = arr;
  }
  if (status == 1) {
    arr = arr.filter((item) => {
      return item.unread_count > 0;
    });
  }

  if (status == 2) {
    arr = arr.filter((item) => {
      return item.topMessage.fromId == item.topMessage.peerId;
    });
  }

  if (status == 3) {
    arr = arr.filter((item) => {
      return (
        item.unread_count == 0 &&
        item.topMessage.fromId != item.topMessage.peerId
      );
    });
  }

  return arr;
}

async function parceEntries(array) {
  const promises = array.map(async (item) => {
    // let avatar = await doSomethingAsync(item);
    let avatarDomStr = item[1].dom.avatarEl.element.innerHTML;
    const regex = /src=\"(.*?)\"/;
    const match = avatarDomStr.match(regex);
    const src = match ? match[1] : null;
    return await blobUrlToBase64(src);
  });
  await Promise.all(promises);
}

function sendToPc(flag, data, method = "POST") {
  if (method == "POST") {
    console.log("**sendToPc :>> post", data);
    fetch(flag, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data }),
    });
  } else {
    console.log("**sendToPc :>> GET", data);

    fetch(flag, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}

export function getIndexDB() {
  rootScope.managers.appStoragesManager.loadStorages().then((res) => {
    console.log("******************* :>> ", res.dialogs);
  });
}
export function setTransCfg(config) {
  if (config.receive != null) {
    localStorage.setItem("translate-receive", config.receive);
  }

  if (config.send != null) {
    localStorage.setItem("translate-send", config.send);
  }
}

export function getTransCfg() {
  const receive = "1" === localStorage.getItem("translate-receive");
  const rend = "1" === localStorage.getItem("translate-send");

  return { receive: receive ? 1 : 0, send: rend ? 1 : 0 };
}

export function setFansTransLan(tgId,lan){
  if(tgId != null && lan != null){
    localStorage.setItem(`${tgId}-translate-lang`, lan);
  }
}

export function getFansTransLan(tgId){
  if(tgId != null){
    return localStorage.getItem(`${tgId}-translate-lang`);
  }
  return null
}

export function bringToInput(content) {
  rootScope.dispatchEvent("set_input", content);
}

export function sendToChat(data) {
  console.log("**sendToChat==", JSON.stringify(data));
  sendMsg(data.type, data.content);
}

async function groupSend(data) {
  console.log("**groupSend :>> ", "groupSend");
  console.log("**groupSend 触发时间:>> ", Date.now());
  let { targetIds, msgDuration, dialogDuration, content } = data;

  let msg_rd_min = parseInt(msgDuration.split("-")[0]);
  let msg_rd_max = parseInt(msgDuration.split("-")[1]);

  let dialog_rd_min = parseInt(dialogDuration.split("-")[0]);
  let dialog_rd_max = parseInt(dialogDuration.split("-")[1]);

  for (const peerId of targetIds) {
    await singleSendMsgDelay(peerId, content, msg_rd_min, msg_rd_max);

    // 计算两个用户之间的随机延迟
    let dialogDelay =
      Math.floor(Math.random() * (dialog_rd_max - dialog_rd_min + 1)) +
      dialog_rd_min;
    await new Promise((resolve) => setTimeout(resolve, dialogDelay * 1000));
  }
  console.log("**groupSend 结束时间:>> ", Date.now());
  return true;
}

function singleSendMsgDelay(peerId, content, msg_rd_min, msg_rd_max) {
  return new Promise((resolve) => {
    console.log("**singleSendMsgDelay for peerId:>> ", peerId);
    console.log("**singleSendMsgDelay 触发时间:>> ", Date.now());
    let totalDelay = 0;
    msg_rd_min = parseInt(msg_rd_min);
    msg_rd_max = parseInt(msg_rd_max);

    content.forEach((msgItem, index) => {
      let rd_s =
        Math.floor(Math.random() * (msg_rd_max - msg_rd_min + 1)) + msg_rd_min;
      totalDelay += rd_s;

      setTimeout(() => {
        console.log("**singleSendMsgDelay 单条消息 触发时间:>> ", Date.now());
        if (msgItem.type == "1") {
          sendText(peerId, msgItem.content);
        } else if (msgItem.type == "2") {
          sendImageByBase64(peerId, msgItem.base64);
        }

        if (index === content.length - 1) {
          resolve(); // 解决 Promise 当所有消息发送完毕
        }
      }, totalDelay * 1000);
    });
  });
}

async function delConv(peerId) {
  try {
    // rootScope.dispatchEvent("del_convs");
    let indexDb = await rootScope.managers.appStoragesManager.loadStorages();
    let users = indexDb.users;
    console.log("**delConv :>> ", peerId);

    let arr = [];
    // for (let k of users) {
    //   arr.push(String(k));
    // }
    users.forEach((item) => {
      arr.push(String(item.id));
    });
    console.log("**del arr :>> ", arr);
    if (arr.indexOf(String(peerId)) > -1) {
      console.log("**del 普通用户 :>> ", peerId);
      await rootScope.managers.appMessagesManager.flushHistory(
        Number(peerId),
        false,
        true
      ); //删普通用户
    } else {
      console.log("**del 群组 channel :>> ", peerId);
      await rootScope.managers.appChatsManager.delete(Number(peerId));
    }
    // rootScope.managers.appMessagesManager.flushHistory(peerId, false); //删普通用户
    // rootScope.managers.appChatsManager.delete(peerId); //删群组
    rootScope.managers.dialogsStorage?.dropDialog(Number(peerId));
  } catch (e) {
    return Promise.reject("无权限，删除失败，请重新检查!!");
  }
  return Promise.resolve(true);
}

async function delConvs(ids) {
  console.log("**delConv :>> ", ids);
  for (let index = 0; index < ids.length; index++) {
    const peerId = ids[index];
    await delConv(Number(peerId));
  }
  return Promise.resolve(true);
}

let timer = null;
function setHeartCheck() {
  if (timer) return;
  timer = setInterval(() => {
    console.log("**setHeartCheck :>> ", Date.now());
    Api_OnlineHeart();
  }, 30000);
}

async function getHistoryByPeerId(peerId) {
  return await rootScope.managers.appMessagesManager.getHistoryMessagesStorage(
    peerId
  );
}

async function loadDialogs(peerId) {
  let peer = await rootScope.managers.appPeersManager.getInputPeerById(peerId);

  const historyResult = await rootScope.managers.appMessagesManager.getHistory({
    peerId: peerId,
    limit: 1000,
  });
  console.log("**historyResult :>> ", historyResult);
  // console.log("**res :>> ", res);
  // let f = AppDialogsManager.xd;
  // let res = await f.onChatsScroll();
  // let r = await res.renderPromise;
  // console.log("**f :>> ", r);
  // let res = await rootScope.managers.apiManager.invokeApi({
  //   method: "messages.getHistory",
  //   params: {
  //     peerId: peerId,
  //     limit: 1000,
  //   },
  // });
  let res = await rootScope.managers.apiManager.invokeApi(
    "messages.getHistory",
    {
      peer: peer,
      limit: 1000,
    }
  );
  console.log("**res :>> ", res);
}

async function bot_sendMessage(chat_id, text) {
  // https://api.telegram.org/bot123456:ABC-DEF1234ghIkl-zyx57W2v1u123ew11/getMe
  Api_TgBot_Report({
    chat_id: import.meta.env.VITE_BASE_YAYNIGHT_PEER_ID,
    text,
  });
}

window.loadDialogs = loadDialogs;
window.setHeartCheck = setHeartCheck;
window.getAIConfig = getAIConfig;
window.getAIReplyStatus = getAIReplyStatus;
window.toggleAIReply = toggleAIReply;
window.getUserInfo = getUserInfo;
window.setTyping = setTyping;
window.setHasRead = setHasRead;
window.setUserInfo2Pc = setUserInfo2Pc;
window.setAIConfig = setAIConfig;

window.setTransCfg = setTransCfg; // 设置翻译开关
window.getTransCfg = getTransCfg; // 获取翻译开关
window.setFansTransLan = setFansTransLan; // 设置翻译语言
window.getFansTransLan = getFansTransLan; // 获取翻译语言

window.setActiveUser = setActiveUser; //查询到用户信息给客户端
window.getConvs = getConvs; //查询到用户信息给客户端

window.bringToInput = bringToInput; // 设置输入框内容
window.sendToChat = sendToChat; // 发送私聊消息

window.groupSend = groupSend; // 群发消息
window.clearUserInfo = cleanUserInfo;
window.delConvs = delConvs;
window.Send_image_ai = Send_image_ai;

window.test = async () => {
  let a = await getTotalUnreadCount();
  console.log("test===>", a);
};

getAIConfig();

TolinkBroadcaster.get().addCallback((e) => {
  console.log("TolinkBroadcaster----", e);
  if (isTolinkLogin()) {
    switch (e.event) {
      case "db:change":
        handleDBChange(e.data);
        break;
    }
  }
});

// TolinkObservers.get().observerDialogs()

/**
 * {
        key: entryName,
        value: value,
        storeName: storeName ?? this.storeName
    }
 * @param {} data 
 */
function handleDBChange(data) {
  if (data) {
    if (data.storeName == "dialogs") {
      // 列表有更新
      refreshUnreadToPc();
    }
  }
}

async function refreshUnreadToPc() {
  if (IDB.hasInstance("tweb")) {
    const count = await getTotalUnreadCount();
    fetch(`yaychat://update_unread`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        totalCount: count,
      }),
    });
  }
}

const promiseMiddlewareDom = {};
async function getTotalUnreadCount() {
  if (IDB.hasInstance("tweb")) {
    const idb = new IDBStorage({ name: "tweb" });
    const allDialogs = await idb.getAll("dialogs");
    const { deferred, middleware } = setPromiseMiddleware(
      promiseMiddlewareDom,
      "getTotalUnreadCount"
    );
    if (allDialogs) {
      let totalCount = 0;
      for (let index = 0; index < allDialogs.length; index++) {
        const dialog = allDialogs[index];
        const isTopic = dialog._ === "forumTopic";
        const { peerId } = dialog;
        const promise = Promise.all([
          peerId.isAnyChat() && !isTopic
            ? getProxiedManagers()
                .acknowledged.dialogsStorage.getForumUnreadCount(peerId)
                .then((result) => {
                  if (result.cached) {
                    return result.result;
                  } else {
                    return { count: 0, hasUnmuted: false };
                  }
                })
                .catch(() => {})
            : undefined,
        ]);

        let [forumUnreadCount] = await middleware(promise);
        const { count: unreadTopicsCount, hasUnmuted: hasUnmutedTopic } =
          forumUnreadCount || {};
        const unreadCount = unreadTopicsCount ?? dialog.unread_count;
        totalCount += unreadCount;
      }
      return totalCount;
    }
  }
  return 0;
}
