import {
  Api_AutoReply,
  Api_ResetAutoReply,
  Api_AiChat,
  Api_AiOpenSession,
  Api_AiSendMessage,
  Api_AiResetSession,
  getMeta,
  Api_TgBot_Report,
} from "./api";
// import { AppMessagesManager } from "../lib/appManagers/appMessagesManager";
import { AppManager } from "../lib/appManagers/manager";
import rootScope from "../lib/rootScope";
import opusDecodeController from "../lib/opusDecodeController";
import readOnlineFile from "./utils/readFile";
import { readOnlineVoice, getDuration } from "./utils/readFile";
import { setHasRead, setTyping } from "./utils/pcApi";
import { CacheTypes, getCache, putCache } from "./utils/cacheUtil";
import IDBStorage from "../lib/files/idb";
import {
  getUserNameByPeerId,
  getFullNameByPeerId,
  getSessionStorage,
  setSessionStorage,
} from "./utils/index";
let msg_pool_map = {};

let msg_pool_ai_map = {};

let receiveMsg = null;

let manager = rootScope.managers;
export class Foo {
  appMessagesManager = new AppManager();
}

async function bot_sendMessage(chat_id, text) {
  // https://api.telegram.org/bot123456:ABC-DEF1234ghIkl-zyx57W2v1u123ew11/getMe
  Api_TgBot_Report({
    chat_id: import.meta.env.VITE_BASE_YAYNIGHT_PEER_ID,
    text,
  });
}

async function isFansOpenAi(sysType, sysId) {
  let res = await getMeta({ sysType, sysId });
  if (res.code == 200) {
    if (res.data.aiReply == 1) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

function isInPoolMap(toUid) {
  if (msg_pool_map[toUid]) {
    return true;
  } else {
    return false;
  }
}
function isInAIPoolMap(toUid) {
  if (msg_pool_ai_map[toUid]) {
    return true;
  } else {
    return false;
  }
}

function getTypeOfMsg(msg) {
  console.log("msg.message==true :>> ", msg.message == true);
  if (msg.message.message && msg.message.message != "") {
    return "text";
  }

  if (msg.message.media) {
    if (msg.message.media._ == "messageMediaDocument") {
      if (
        msg.message.media.document.type == "video" ||
        msg.message.media.document.type == "round"
      ) {
        return "video";
      } else {
        return msg.message.media.document.type;
      }
    }
    if (msg.message.media._ == "messageMediaPhoto") {
      if (msg.message.media.photo) {
        return "image";
      }
    }
  }
}

function init_conversition(conversationId) {
  Api_AiResetSession({
    conversation_id: Number(conversationId),
  }).then((res) => {});
}
function bot_text(uid) {
  let str = `🎉🎉 Extra reward from Admin! Bubbly, Spaceman, Bouquet!  
Grab THESE NEW HUBBIES! 

    ${uid} 
    
Test proved, voice message plus a selfie nail men!`;

  return str;
}
async function getReplyFormAi(received_msg, conv_info) {
  console.log("**getReplyFormAi :>> ", received_msg);
  // let indexDb = await rootScope.managers.appStoragesManager.loadStorages();
  // let users = indexDb.users;
  // let userInfo = users.find((item) => {
  //   return item.id == JSON.parse(localStorage.getItem("user_auth")).id;
  // });
  // let res = await Api_AiChat({
  //   userName: received_msg.message.fromId,
  //   proxyUserName: JSON.parse(localStorage.getItem("user_auth")).id,
  //   aiName: userInfo.last_name
  //     ? userInfo.first_name + " " + userInfo.last_name
  //     : userInfo.first_name,
  //   msgTag: "",
  //   message: received_msg.message.message,
  // });

  // if (res.data.commandList != null) {
  //   let msgMap = {
  //     toUid: received_msg.message.fromId * 1,
  //     msgPool: res.data.commandList,
  //   };
  //   return msgMap;
  // } else {
  //   return null;
  // }

  // SuggestPreference, //推荐偏好
  // SuggestPopularize, //推广应用
  // UserRegisterFinish, //用户注册结束
  // EndSession;
  let message = "";
  let message_type = getTypeOfMsg(received_msg);
  if (message_type == "sticker") {
    message = received_msg.message.media.document.stickerEmojiRaw;
  } else {
    message = received_msg.message.message;
  }
  console.log("**message_type :>> ", message_type);
  console.log("**Date getReplyFormAi :>> ", Date.now());
  let usernames = await getUserNameByPeerId(received_msg.message.fromId);
  let fullName = await getFullNameByPeerId(received_msg.message.fromId);
  console.log("**usernames :>> ", usernames);
  console.log("**fullName :>> ", fullName);
  console.log("****conv_info", conv_info);
  let res = await Api_AiSendMessage({
    conversationId: conv_info.id,
    type: "MSG_TEXT",
    message,
    fullName,
    inviteCode: sessionStorage.getItem("tolink_inviteCode") ?? null,
    targetApp: sessionStorage.getItem("tolink_targetApp") ?? null,
    tag: null,
    fromUser: true,
    debugStatus: "",
  });

  if (res.data.info != undefined) {
    console.log("****存在AI状态 :>> ", res.data.info.array);
    let el = document.querySelector(
      `[data-peer-id="${received_msg.message.fromId}"]`
    );
    // let self_peerid = JSON.parse(localStorage.getItem("user_auth")).id;

    // let aiStatusKey = `${received_msg.message.fromId}_${self_peerid}`;

    // await putCache(
    //   aiStatusKey,
    //   CacheTypes.MSG_LOG,
    //   `对话ID:${conv_info.id}|${res.data.info.array}`
    // );

    // let el = document.querySelector(`[data-peer-id="6530200130"]`);
    console.log("****获取聊天的DOM :>> ", el);
    let aibox = el.querySelector(".ai-status-row");
    console.log("****获取到AI状态行 :>> ", aibox);
    aibox.innerHTML = `对话ID:${conv_info.id}|${res.data.info.array}`;

    if (res.data.info.hasOwnProperty("extra")) {
      let new_user_list = getSessionStorage("new_user_list");
      console.log("****new_user_list :>> ", new_user_list);
      if (new_user_list == null) {
        new_user_list = [];
      }
      console.log("****new_user_list :>> ", new_user_list);
      if (!new_user_list.includes(res.data.info.extra)) {
        console.log(`****新用户注册！\n${res.data.info.extra}`);
        let bot_peerid = import.meta.env.VITE_BASE_BOT_PEER_ID;
        // Send_text(
        //   bot_peerid,
        //   `*yaychat-report:new-user*|id:${res.data.info.extra}`
        // );
        let text = bot_text(res.data.info.extra);
        bot_sendMessage(bot_peerid, text);
        new_user_list.push(res.data.info.extra);
      }

      setSessionStorage("new_user_list", new_user_list);
    }
  }

  if (res.data.chatMessages != null) {
    let msgMap = {
      toUid: received_msg.message.fromId * 1,
      msgPool: res.data.chatMessages,
    };
    return msgMap;
  } else {
    return null;
  }
}

async function getReplyFormServer(received_msg) {
  let type = getTypeOfMsg(received_msg);

  if (received_msg.message.message == "/init") {
    init_conversition(received_msg);
    return;
  }

  let res = await Api_AutoReply({
    user: {
      id: JSON.parse(localStorage.getItem("user_auth")).id,
    },
    msg: {
      id: received_msg.message.mid,
      fromUid: received_msg.message.fromId,
      type: type,
      body: type == "text" ? received_msg.message.message : null,
    },
  });

  console.log("**res :>> ", res);

  if (res.data.msg != null) {
    res.data.msg.payloads.forEach((m, idx) => {
      m.delay = res.data.strategy.delay[idx] * 1000;
    });
    let msgMap = {
      toUid: res.data.msg.toUid * 1,
      msgPool: res.data.msg.payloads,
    };
    return msgMap;
  } else {
    return null;
  }
}

async function getConversitionInfoByPeerId(peerId) {
  // let indexDb = await rootScope.managers.appStoragesManager.loadStorages();
  // let users = indexDb.users;
  let idb = new IDBStorage({ name: "tweb" });
  let users = await idb.getAll("users");

  let userInfo = users.find((item) => {
    return item.id == JSON.parse(localStorage.getItem("user_auth")).id;
  });

  let res = await Api_AiOpenSession({
    organization: null,
    chatChannel: null,
    inviteCode: sessionStorage.getItem("tolink_inviteCode") ?? null,
    targetApp: sessionStorage.getItem("tolink_targetApp") ?? null,
    userName: peerId,
    proxyUserName: JSON.parse(localStorage.getItem("user_auth")).id,
    aiName: getAIName(userInfo),
    sessionName: null,
    close: false,
  });

  if (res.code == 0) {
    return res.data;
  }
}
async function getConversitionInfo(received_msg) {
  // let indexDb = await rootScope.managers.appStoragesManager.loadStorages();
  // let users = indexDb.users;
  let idb = new IDBStorage({ name: "tweb" });
  let users = await idb.getAll("users");

  let userInfo = users.find((item) => {
    return item.id == JSON.parse(localStorage.getItem("user_auth")).id;
  });

  let res = await Api_AiOpenSession({
    organization: null,
    chatChannel: null,
    inviteCode: sessionStorage.getItem("tolink_inviteCode") ?? null,
    targetApp: sessionStorage.getItem("tolink_targetApp") ?? null,
    userName: received_msg.message.fromId,
    proxyUserName: JSON.parse(localStorage.getItem("user_auth")).id,
    aiName: getAIName(userInfo),
    sessionName: null,
    close: false,
  });

  if (res.code == 0) {
    return res.data;
  }
}

function isTGNotificationAccount(peerId) {
  if (peerId == 777000) {
    return true;
  } else {
    return false;
  }
}

function getAIName(userInfo) {
  let firstName = userInfo.first_name ?? "";
  let split = firstName.length > 0 ? " " : "";
  let lastName = userInfo.last_name ?? "";
  return firstName + split + lastName;
}

export async function setAiMsg2Pool(received_msg) {
  //官方消息，直接忽略
  if (isTGNotificationAccount(received_msg.message.fromId)) {
    return;
  }
  let AI_auto_reply = await getAIReplyStatus();
  console.log("setAiMsg2Pool===", AI_auto_reply);
  if (
    AI_auto_reply == 0 ||
    AI_auto_reply == null ||
    AI_auto_reply == undefined
  ) {
    return;
  }
  let fans_ai_open = await isFansOpenAi(1, received_msg.message.fromId);
  if (!fans_ai_open) {
    return;
  }

  receiveMsg = received_msg;
  let key = `${received_msg.message.fromId}_${
    JSON.parse(localStorage.getItem("user_auth")).id
  }`;

  let cacheConver = await getCache(key, CacheTypes.CONVERSATIONS);
  let conv_info = null;
  console.log("**cacheConver :>> ", cacheConver);
  if (!cacheConver) {
    conv_info = await getConversitionInfo(received_msg);
    await putCache(key, CacheTypes.CONVERSATIONS, conv_info);
  } else {
    conv_info = cacheConver;
  }

  if (received_msg.message.message == "*init*") {
    init_conversition(conv_info.id);
    return;
  }

  console.log("******conv_info :>> ", conv_info);

  let msgMap = await getReplyFormAi(received_msg, conv_info);
  console.log("****msgMapmsgMap :>> ", msgMap);
  if (isInAIPoolMap(msgMap.toUid)) {
    //存在消息池
    msgMap.msgPool.forEach((m) => {
      msg_pool_ai_map[msgMap.toUid].msgArr.push(m);
    });
  } else {
    //不存在消息池
    msg_pool_ai_map[msgMap.toUid] = {
      msgArr: [],
    };
    msgMap.msgPool.forEach((m) => {
      msg_pool_ai_map[msgMap.toUid].msgArr.push(m);
    });
  }

  let randomTime = Math.floor(Math.random() * 2000 + 1000);
  console.log(
    "**Date randomTime 本次消息延迟时间+randomTime :>> " + randomTime,
    Date.now()
  );
  let timer = null;
  timer = setTimeout(() => {
    if (msgMap.msgPool.length != 0) {
      setHasRead(received_msg.message);
    }
    console.log("**Date 开始发送消息时间:>> ", Date.now());
    TL_send_ai_message(msgMap.toUid);
  }, randomTime);
}

export async function TL_send_ai_message(peerId) {
  console.log("****Date TL_send_ai_message :>> ", Date.now());

  if (msg_pool_ai_map[peerId].msgArr.length != 0) {
    let msg = msg_pool_ai_map[peerId].msgArr.shift();
    // let timer = null;
    // timer = setTimeout(() => {
    //   Send_text(peerId, msg.body);
    //   setTyping(peerId);
    //   clearTimeout(timer);
    // }, msg.delay);
    if (msg.type == "MSG_TEXT") {
      Send_text_ai(peerId, msg.value);
    }
    if (msg.type == "MSG_IMAGE") {
      Send_image_ai(peerId, msg.value);
    }
    if (msg.type == "MSG_SERVER_DELAY_ACTION") {
      let timer = null;

      timer = setTimeout(() => {
        console.log("*****异步处理DELAY :>>!!!!!! ");
        Send_delay_ai(peerId, msg.value);
      }, msg.value.second * 1000);

      TL_send_ai_message(peerId);
    }

    if (msg.type == "TYPING") {
      setTypingStatus(peerId * 1, msg.value * 1 + 1000);
    }
  }
}
export async function setMsg2Pool(received_msg) {
  let AI_auto_reply = await getAIReplyStatus();
  if (
    AI_auto_reply == 0 ||
    AI_auto_reply == null ||
    AI_auto_reply == undefined
  ) {
    return;
  }
  // open_session 检查对话组状态

  let msgMap = await getReplyFormServer(received_msg);

  if (isInPoolMap(msgMap.toUid)) {
    //存在消息池
    msgMap.msgPool.forEach((m) => {
      msg_pool_map[msgMap.toUid].msgArr.push(m);
    });
  } else {
    //不存在消息池
    msg_pool_map[msgMap.toUid] = {
      msgArr: [],
    };
    msgMap.msgPool.forEach((m) => {
      msg_pool_map[msgMap.toUid].msgArr.push(m);
    });
  }

  let randomTime = Math.floor(Math.random() * 2000 + 1000);
  let timer = null;
  timer = setTimeout(() => {
    setHasRead(received_msg.message);
    TL_send_message(msgMap.toUid);
    console.log("**randomTime :>> ", randomTime);
  }, randomTime);
}

export async function TL_send_message(peerId) {
  // console.log("**TL_send_message");
  // Send_text(peerId, text);

  if (msg_pool_map[peerId].msgArr.length != 0) {
    let msg = msg_pool_map[peerId].msgArr.shift();

    // msg.delay = 100;

    if (msg.type == "text") {
      let timer = null;
      timer = setTimeout(() => {
        Send_text(peerId, msg.body);
        setTyping(peerId);
        clearTimeout(timer);
      }, msg.delay);
    }

    if (msg.type == "image") {
      let timer = null;
      timer = setTimeout(() => {
        Send_image(peerId, msg.body);
        setTyping(peerId);
        clearTimeout(timer);
      }, msg.delay);
    }

    if (msg.type == "voice") {
      let timer = null;
      timer = setTimeout(() => {
        Send_voice(peerId, msg.body);
        setTyping(peerId);
        clearTimeout(timer);
      }, msg.delay);
    }
    if (msg.type == "video") {
      let timer = null;
      timer = setTimeout(() => {
        Send_video(peerId, msg.body);
        setTyping(peerId);
        clearTimeout(timer);
      }, msg.delay);
    }
  }
}

export function Send_text_ai(peerId, text) {
  rootScope.managers.appMessagesManager
    .sendText(peerId, text, {
      peerId,
      noWebPage: true,
      webPage: null,
      webPageOptions: null,
    })
    .then((res) => {
      console.log("**消息发送后触发检测");
      TL_send_ai_message(peerId);
    });
}

export async function Send_delay_ai(peerId, e) {
  let key = `${peerId}_${JSON.parse(localStorage.getItem("user_auth")).id}`;
  let cacheConver = await getCache(key, CacheTypes.CONVERSATIONS);
  let conv_info = null;
  if (!cacheConver) {
    conv_info = await getConversitionInfoByPeerId(peerId);
    await putCache(key, CacheTypes.CONVERSATIONS, conv_info);
  } else {
    conv_info = cacheConver;
  }
  let usernames = await getUserNameByPeerId(peerId);
  let fullName = await getFullNameByPeerId(peerId);
  let res = await Api_AiSendMessage({
    conversationId: conv_info.id,
    type: "MSG_SERVER_DELAY_ACTION",
    message: JSON.stringify(e),
    inviteCode: sessionStorage.getItem("tolink_inviteCode") ?? null,
    targetApp: sessionStorage.getItem("tolink_targetApp") ?? null,
    fullName: fullName,
    tag: null,
    fromUser: true,
    debugStatus: null,
  });
  if (res.data.chatMessages != null) {
    let msgMap = {
      toUid: peerId * 1,
      msgPool: res.data.chatMessages,
    };
    if (isInAIPoolMap(msgMap.toUid)) {
      //存在消息池
      msgMap.msgPool.forEach((m) => {
        msg_pool_ai_map[msgMap.toUid].msgArr.push(m);
      });
    } else {
      //不存在消息池
      msg_pool_ai_map[msgMap.toUid] = {
        msgArr: [],
      };
      msgMap.msgPool.forEach((m) => {
        msg_pool_ai_map[msgMap.toUid].msgArr.push(m);
      });
    }

    let randomTime = Math.floor(Math.random() * 2000 + 1000);
    console.log(
      "**Date randomTime 本次消息延迟时间+randomTime :>> " + randomTime,
      Date.now()
    );
    let timer = null;
    timer = setTimeout(() => {
      //  setHasRead(received_msg.message);
      console.log("**Date 开始继续循环队列:>> ", Date.now());
      TL_send_ai_message(msgMap.toUid);
    }, randomTime);
  } else {
    return null;
  }
}

export async function Send_delay_ai_old(peerId, e) {
  let received_msg = receiveMsg;

  let key = `${received_msg.message.fromId}_${
    JSON.parse(localStorage.getItem("user_auth")).id
  }`;

  let cacheConver = await getCache(key, CacheTypes.CONVERSATIONS);
  let conv_info = null;
  console.log("**cacheConver :>> ", cacheConver);
  if (!cacheConver) {
    conv_info = await getConversitionInfo(received_msg);
    await putCache(key, CacheTypes.CONVERSATIONS, conv_info);
  } else {
    conv_info = cacheConver;
  }

  let message = "";
  let message_type = getTypeOfMsg(received_msg);
  if (message_type == "sticker") {
    message = received_msg.message.media.document.stickerEmojiRaw;
  } else {
    message = received_msg.message.message;
  }
  console.log("**message_type :>> ", message_type);
  console.log("**Date getReplyFormAi :>> ", Date.now());
  let usernames = await getUserNameByPeerId(received_msg.message.fromId);
  let fullName = await getFullNameByPeerId(received_msg.message.fromId);
  console.log("**usernames :>> ", usernames);
  console.log("**fullName :>> ", fullName);
  let res = await Api_AiSendMessage({
    conversationId: conv_info.id,
    type: "MSG_SERVER_DELAY_ACTION",
    message: JSON.stringify(e),
    inviteCode: sessionStorage.getItem("tolink_inviteCode") ?? null,
    targetApp: sessionStorage.getItem("tolink_targetApp") ?? null,
    fullName: fullName,
    tag: null,
    fromUser: true,
    debugStatus: null,
  });

  console.log("**res.data.chatMessages :>> ", res.data.chatMessages);

  if (res.data.chatMessages != null) {
    let msgMap = {
      toUid: received_msg.message.fromId * 1,
      msgPool: res.data.chatMessages,
    };
    if (isInAIPoolMap(msgMap.toUid)) {
      //存在消息池
      msgMap.msgPool.forEach((m) => {
        msg_pool_ai_map[msgMap.toUid].msgArr.push(m);
      });
    } else {
      //不存在消息池
      msg_pool_ai_map[msgMap.toUid] = {
        msgArr: [],
      };
      msgMap.msgPool.forEach((m) => {
        msg_pool_ai_map[msgMap.toUid].msgArr.push(m);
      });
    }

    let randomTime = Math.floor(Math.random() * 2000 + 1000);
    console.log(
      "**Date randomTime 本次消息延迟时间+randomTime :>> " + randomTime,
      Date.now()
    );
    let timer = null;
    timer = setTimeout(() => {
      setHasRead(received_msg.message);
      console.log("**Date 开始发送消息时间:>> ", Date.now());
      TL_send_ai_message(msgMap.toUid);
    }, randomTime);
  } else {
    return null;
  }

  // let resp = await Api_AiSendMessage({
  //   conversationId: 14,
  //   type: "MSG_SERVER_DELAY_ACTION",
  //   message: JSON.stringify(e),
  //   tag: null,
  //   fromUser: true,
  //   debugStatus: null,
  // });
}
export function Send_text(peerId, text) {
  rootScope.managers.appMessagesManager
    .sendText(peerId, text, {
      peerId,
      noWebPage: true,
      webPage: null,
      webPageOptions: null,
    })
    .then((res) => {
      console.log("**消息发送后触发检测");
      TL_send_message(peerId);
    });
}
export function Send_image(peerId, body) {
  readOnlineFile(body).then((file) => {
    console.log(`**file`, file);
    rootScope.managers.appMessagesManager.sendFile(peerId, {
      peerId,
      file,
      objectURL: body,
      isMedia: true,
      clearDraft: true,
    });
  });
  TL_send_message(peerId);
}
export function Send_image_ai(peerId, body) {
  readOnlineFile(body).then((file) => {
    console.log(`**file`, file);
    rootScope.managers.appMessagesManager.sendFile(peerId, {
      peerId,
      file,
      objectURL: body,
      isMedia: true,
      clearDraft: true,
    });
  });
  TL_send_ai_message(peerId);
}

export function setTypingStatus(peerId, times) {
  console.log("**setTypingStatus :>> ", times);
  setTyping(peerId);
  let timer = null;
  timer = setTimeout(() => {
    TL_send_ai_message(peerId);
    clearTimeout(timer);
  }, times);
  // setTyping(peerId);
  // TL_send_ai_message(peerId);
}
export async function Send_voice(peerId, body) {
  // console.log("**image暂未实现 :>> ");
  readOnlineFile(body).then(async (file) => {
    let duration = await getDuration(file);
    readOnlineVoice(body).then((typedArray) => {
      opusDecodeController.decode(typedArray, true).then((result) => {
        opusDecodeController.setKeepAlive(false);
        console.log("**opusresult :>> ", result);
        rootScope.managers.appMessagesManager.sendFile(peerId, {
          peerId,
          file,
          isVoiceMessage: true,
          isMedia: true,
          duration: duration,
          waveform: result.waveform,
          objectURL: result.url,
          clearDraft: true,
        });
      });
    });
  });
  TL_send_message(peerId);
}
export function Send_video(peerId, body) {
  console.log("**Send_video暂未实现 :>> ");
  TL_send_message(peerId);
}
export function sendFile(peerId, file) {
  console.log("**sendFile暂未实现 :>> ");
}
